@import-normalize; /* bring in normalize.css styles */

/* rest of app styles */
html {
  height: 100%;
}
body {
  margin: 0;
  /*font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;*/
  font: 14px "Roboto", sans-serif;
  height: 100%;
}
button {
  font-family: inherit;
}
::-moz-selection {
    color: #fff;
    background: #1890ff
}
::selection {
    color: #fff;
    background: #1890ff
}
h1 {
  margin-bottom: .5em;
  color: rgba(0,0,0,0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2 {
  margin-bottom: .5em;
  color: rgba(0,0,0,0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
#root {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}