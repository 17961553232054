.rc-notification {
    position: fixed;
    z-index: 1000;
    display: flex;
    align-items: center;
    max-height: 100vh;
    padding: 10px;
}
.rc-notification-top, .rc-notification-topLeft, .rc-notification-topRight {
    top: 0;
    flex-direction: column;
}
.rc-notification-top {
    left: 50%;
    transform: translateX(-50%);
}
.rc-notification-topRight {
    right: 0;
}
.rc-notification-notice {
    box-shadow: 0 3px 6px -4px #0000001f,0 6px 16px #00000014,0 9px 28px 8px #0000000d;
    position: relative;
    display: block;
    box-sizing: border-box;
    width: auto;
    margin: 12px 0;
    line-height: 1.5;
    background: #fff;
    border: 1px solid #999;
    border: 0px solid rgba(0,0,0,0);
    border-radius: 3px 3px;
}
.rc-notification-notice-content {
    background: #fff;
    color: #000;
}
/*.rc-notification-notice-closable .rc-notification-notice-content {
    padding-right: 20px; 
}*/
.rc-notification-notice-close {
    position: absolute;
    top: 3px;
    right: 5px;
    color: #000;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    text-decoration: none;
    text-shadow: 0 1px 0 #fff;
    outline: none;
    cursor: pointer;
    opacity: 0.2;
    filter: alpha(opacity=20);
}
.rc-notification-notice-close-x:after {
    content: '×';
}
.rc-notification-notice-close:hover {
    text-decoration: none;
    opacity: 1;
    filter: alpha(opacity=100);
}
.rc-notification-fade {
    overflow: hidden;
    transition: all 0.3s;
}
.rc-notification-fade-appear-start {
    transform: translateY(100%);
    opacity: 0;
}
.rc-notification-fade-appear-active {
    transform: translateY(0);
    opacity: 1;
}
